<template>
    <v-container fill-height>

                <v-sheet color="white" elevation="20" class="pa-2 mt-6">
                    <v-toolbar flat>
                        <v-toolbar-title>
                            Pedidos por Proveedor
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-row>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="issueDateFrom" label="Desde" hide-details="auto" required
                                    outlined type="date" />
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="issueDateTo" label="Hasta" hide-details="auto" required outlined
                                    type="date" />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn x-large dark color="success" @click="get_items">Buscar</v-btn>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <v-row>
                        <v-col cols="12" v-for="(itms, index) in items" :key="index">
                            <v-data-table :headers="headers[$vuetify.breakpoint.xsOnly]" :items="itms"
                                :items-per-page="7" item-key="code" sort-by="name" mobile-breakpoint="0"
                                class="table-cursor" :search="search" loading-text="Cargando ..."
                                @click:row="handleClick">
                                <template #top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>{{ suppliers.find(item => item.code == index).name }}
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>

                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
                                    <v-avatar v-if="item.picture">
                                        <v-img :src="getImagePhoto(item.account, '000000', item.picture)"></v-img>
                                    </v-avatar>
                                </template>
                                <template v-slot:[`item.size`]="{ item }" class="pa-0 ma-0">
                                    {{ item.size + " " + item.uom }}
                                </template>
                                <template v-slot:[`item.tqty`]="{ item }" class="pa-0 ma-0">
                                    {{ item.tqty + " " + item.uom }}
                                </template>
                                <template v-slot:[`item.cost`]="{ item }" class="pa-0 ma-0">
                                    {{ "$" + (item.cost).toLocaleString(2) }}
                                </template>
                                <template v-slot:[`item.tcost`]="{ item }" class="pa-0 ma-0">
                                    {{ "$" + (item.tcost).toLocaleString(2) }}
                                </template>
                                <template v-slot:[`item.price`]="{ item }" class="pa-0 ma-0">
                                    {{ "$" + (item.price).toLocaleString(2) }}
                                </template>
                                <template slot="body.append">
                                    <tr>
                                        <th>Totales</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th class="text-center">
                                            {{ sumField(index, "tqty").toLocaleString(2) }}
                                        </th>
                                        <th></th>
                                        <th class="text-right">
                                            {{ "$" + sumField(index, "tcost").toLocaleString(2) }}
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th class="text-right">
                                            {{ "$" + sumField(index, "price").toLocaleString(2) }}
                                        </th>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
                        <v-card color="primary" dark>
                            <v-card-text>
                                Cargando ...
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-sheet>

    </v-container>
</template>

<script>
import { webserver, getToday, getIssueDate } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
//import PartyForm from "../components/PartyForm.vue";
export default {
    components: {},
    mounted() {
        this.get_suppliers();
    },
    data() {
        return {
            table: "suppliers",
            dialog: false,
            newItem: false,
            search: "",
            loading_status: false,
            errors: [],
            items: [],
            item: createItem(),
            headers: {
                false: [
                    {
                        text: "",
                        align: "center",
                        value: "picture",
                        width: 50,
                        groupable: false,
                    },
                    {
                        text: "Categoría",
                        align: "start",
                        sortable: true,
                        value: "category",
                        dataType: "text",
                        groupable: true,
                    },
                    {
                        text: "Marca",
                        align: "start",
                        sortable: true,
                        value: "brand",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "Producto",
                        align: "start",
                        sortable: true,
                        value: "product_name",
                        dataType: "text",
                        groupable: false,
                    },

                    {
                        text: "Pack",
                        align: "center",
                        sortable: true,
                        value: "pack",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "QTY",
                        align: "center",
                        sortable: true,
                        value: "qty",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "SubTotal",
                        align: "center",
                        sortable: true,
                        value: "tqty",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "Costo",
                        align: "end",
                        sortable: true,
                        value: "cost",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "Total Costo",
                        align: "end",
                        sortable: true,
                        value: "tcost",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "FOB",
                        align: "end",
                        sortable: true,
                        value: "fob",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "CIF",
                        align: "end",
                        sortable: true,
                        value: "cif",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "FAS",
                        align: "end",
                        sortable: true,
                        value: "fas",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: " x UOM",
                        align: "end",
                        sortable: true,
                        value: "cost_uom",
                        dataType: "number",
                        groupable: false,
                    },

                    {
                        text: "Precio",
                        align: "end",
                        sortable: true,
                        value: "price",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: " x UOM",
                        align: "end",
                        sortable: true,
                        value: "price_uom",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "%",
                        align: "end",
                        sortable: true,
                        value: "difference",
                        dataType: "number",
                        groupable: false,
                    },
                ],
                true: [
                    {
                        text: "",
                        align: "center",
                        value: "picture",
                        width: 50,
                    },
                    {
                        text: "Producto",
                        align: "start",
                        sortable: true,
                        value: "product_name",
                        dataType: "text",
                    },
                    {
                        text: "Costo",
                        align: "end",
                        sortable: true,
                        value: "cost",
                        dataType: "number",
                    },
                ],
            },
            suppliers: [],
            issueDateFrom: getIssueDate(2),
            issueDateTo: getToday(),
        };
    },
    methods: {
        reduceData(data) {
            var result = [];
            data.reduce(function (res, value) {
                if (!res[value.code]) {
                    res[value.code] = value;
                    res[value.code].qty = parseFloat(res[value.code].qty);
                    res[value.code].cost = parseFloat(res[value.code].cost);
                    res[value.code].pack = parseFloat(res[value.code].pack);
                    res[value.code].price = parseFloat(res[value.code].price);
                    result.push(res[value.code]);
                } else {
                    res[value.code].qty += parseFloat(value.qty);
                }
                return res;
            }, {});
            return result;
        },
        openURL(e) {
            window.open(e, "", "width=400,height=800");
        },
        handleClick(data) {
            this.item = { ...data };
            this.newItem = false;
            this.dialog = true;
        },
        updateLocation(e) {
            this.store.location = e;
        },
        get_suppliers() {
            var qry = {
                account: this.$store.state.profile.account,
                table: "suppliers",
                filters: [],
            };
            this.loading_status = true;
            webserver("get_table", qry, (data) => {
                this.loading_status = false;
                this.suppliers = data;
                this.get_items();
            });
        },
        get_items() {
            var qry = {
                filters: [
                    { field: "account", operator: "=", value: this.$store.state.profile.account },
                    { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
                    { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
                ],
            };
            this.loading_status = true;
            console.log(qry);
            webserver("get_doc_items", qry, (data) => {
                var splr = data.reduce(function (r, a) {
                    r[a.supplier] = r[a.supplier] || [];
                    r[a.supplier].push(a);
                    return r;
                }, Object.create(null));

                for (const [key] of Object.entries(splr)) {
                    var temp = this.reduceData(splr[key]);
                    temp.forEach((rg) => {
                        rg.tqty = parseFloat(rg.size) * parseFloat(rg.qty) * parseFloat(rg.pack);
                        rg.tcost = parseFloat(rg.qty) * parseFloat(rg.pack) * parseFloat(rg.cost);
                        rg.price = parseFloat(rg.qty) * parseFloat(rg.pack) * parseFloat(rg.price);
                    });
                    splr[key] = temp;
                }

                this.loading_status = false;
                this.items = splr;

            });
        },
        refresh() {
            this.dialog = false;
            this.get_items();
        },
        getImagePhoto: function (account, code, src) {
            var url = "https://mayorista.app/photos/" + account + "/" + code + "/" + src;
            return url;
        },
        newForm() {
            this.item = createItem();
            this.item.account = this.$store.state.profile.account;
            this.item.location = "";
            this.valid = false;
            this.newItem = true;
            this.dialog = true;
        },
        call_contact(data) {
            window.location.href = "tel://" + data;
        },
        sumField(fld, key) {
            return this.items[fld].reduce((a, b) => a + (b[key] || 0), 0);
        },
    },
    computed: {
        isMobile() {
            if (screen.width <= 760) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" >
.float {
    position: fixed;
    width: 40px;
    height: 40px;
    top: 64px;
    right: 20px;
    background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.text-start {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
</style>
