var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-sheet',{staticClass:"pa-2 mt-6",attrs:{"color":"white","elevation":"20"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Pedidos por Proveedor ")]),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Desde","hide-details":"auto","required":"","outlined":"","type":"date"},model:{value:(_vm.issueDateFrom),callback:function ($$v) {_vm.issueDateFrom=$$v},expression:"issueDateFrom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Hasta","hide-details":"auto","required":"","outlined":"","type":"date"},model:{value:(_vm.issueDateTo),callback:function ($$v) {_vm.issueDateTo=$$v},expression:"issueDateTo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"x-large":"","dark":"","color":"success"},on:{"click":_vm.get_items}},[_vm._v("Buscar")])],1)],1)],1),_c('v-row',_vm._l((_vm.items),function(itms,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table-cursor",attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":itms,"items-per-page":7,"item-key":"code","sort-by":"name","mobile-breakpoint":"0","search":_vm.search,"loading-text":"Cargando ..."},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.suppliers.find(function (item) { return item.code == index; }).name)+" ")]),_c('v-spacer')],1)]},proxy:true},{key:"item.picture",fn:function(ref){
var item = ref.item;
return [(item.picture)?_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, '000000', item.picture)}})],1):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" ")]}},{key:"item.tqty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tqty + " " + item.uom)+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + (item.cost).toLocaleString(2))+" ")]}},{key:"item.tcost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + (item.tcost).toLocaleString(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + (item.price).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Totales")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.sumField(index, "tqty").toLocaleString(2))+" ")]),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s("$" + _vm.sumField(index, "tcost").toLocaleString(2))+" ")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s("$" + _vm.sumField(index, "price").toLocaleString(2))+" ")])])])],2)],1)}),1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }